'use client';
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import Image from 'next/image';
import { showSliders } from '@/lib/apiRequests';

export default function VerticalSlider() {
  const swiperRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [slides, setSlides] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchSlides = async () => {
      const data = await showSliders();
      if (data && !data.error) {
        setSlides(data?.records);
        setError(false);
      }
      if(data.error) {
        setError(true);
      }
    };

    fetchSlides();
  }, [error]);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (swiperRef.current) {
        // @ts-ignore
        const width = swiperRef.current.clientWidth;
        setContainerWidth(width);
      }
    };

    updateContainerWidth();

    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const swiperHeight = containerWidth * 0.25;
  const margin_x = containerWidth * 0.04;

  return (
    <>
      {!error &&
        <div ref={swiperRef} className="mx-auto max-w-[90rem] mt-2 md:mt-4 lg:mt-4 md:p-auto px-2">
          <Swiper
            direction={'vertical'}
            slidesPerView={1}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            style={{ height: swiperHeight }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className='flex center w-full h-full'>
                  <div className={`rounded-xl overflow-hidden w-full h-full relative`}  >
                    {/* @ts-ignore */}
                    <Image src={slide.image} alt={slide.text || `Slide ${index + 1}`} fill={true} className='object-cover rounded-xl' />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>}
    </>
  );
}
