import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa6'

const Chips = () => {
    const [isChecked, setIsChecked ]= useState(true)
    return (
        <button className={`rounded-full px-4 py-2 border border-primary flex center gap-x-2 ${isChecked ? "bg-primary text-white font-bold":""}`}>
            <div><FaCheck /></div>
            <p>Chips</p>
        </button>
    )
}

export default Chips