"use client";

import SearchBar from "@/components/shared/searchBar";
import React, { useEffect, useRef, useState } from "react";
import LocationComponent from "./location";
import Image from "next/image";
import { IoClose, IoMenu } from "react-icons/io5";
import Link from "next/link";
import AuthModal from "@/components/shared/wrappers/authModal";
import Register from "@/components/views/register";
import Login from "@/components/views/login";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { clearUser } from "@/redux/slices/userSlice";
import { useAuthModal } from "@/app/authModalProvider";
import { Toaster, toast } from "sonner";
import { MdOutlineModeEditOutline } from "react-icons/md";
import ProfileEditModal from "@/components/shared/profileEditModal";
import { clearLocationHistory } from "@/redux/slices/locationSlice";
import { Button } from "@/components/shared/buttons/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/shared/alert-dialog";
import { Trash2, X } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/shared/avatar";

const Navbar = ({ data }: any) => {
  const widthRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  //@ts-ignore
  const { user } = useAppSelector((state) => state?.user);
  const dispatch = useAppDispatch();
  // values from AuthModalContext,  used to control the dialogue box for login/register
  const { modalType, openLoginModal, openRegisterModal, closeModal } =
    useAuthModal();

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(clearLocationHistory());
    toast.success("You have successfully logged out");
  };

  const handleDeleteAccount = () => {
    //TODO: This is just a temporary thing that we are logging out the user and clearing the user state
    // In the future, we will have a separate API endpoint to delete the user account
    dispatch(clearUser());
    dispatch(clearLocationHistory());

    toast.success(
      "Your account deletion request has been received. Your account will be deleted in 90 days."
    );
  };

  // Update container width state on component mount or resize
  useEffect(() => {
    const updateContainerWidth = () => {
      if (widthRef.current) {
        // @ts-ignore
        const width = widthRef.current.clientWidth;
        setContainerWidth(width);
      }
    };
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);

    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  const openProfileEditModal = () => setShowProfileEditModal(true);

  const closeProfileEditModal = () => setShowProfileEditModal(false);

  const margin_x = containerWidth * 0.05;
  return (
    <section className="" ref={widthRef}>
      <Toaster richColors position="top-center" />
      {showProfileEditModal && (
        <ProfileEditModal closeProfileEditModal={closeProfileEditModal} />
      )}

      <div className="max-w-[90rem] mx-auto  flex items-center md:justify-between  flex-wrap  py-3 pl-4 ">
        {/* hamburger menu */}
        <div className="flex items-center flex-grow md:flex-grow-0 w-full md:w-auto order-1 md:gap-x-4 ">
          <div className="flex-shrink-0 mr-3">
            <div
              className="relative cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            >
              <IoMenu className="size-8" />
            </div>

            <div
              className={`absolute h-full left-0 top-0  bg-white z-50  ${
                isOpen
                  ? " w-[270px] md:w-[300px] duration-300"
                  : "w-0 duration-100"
              } overflow-hidden drop-shadow-lg`}
            >
              <div
                className={` flex justify-end pt-4 pr-3 ${
                  user ? "bg-primary" : ""
                }  text-foreground`}
              >
                <X
                  onClick={() => setIsOpen(!isOpen)}
                  className=" rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground cursor-pointer"
                />
              </div>

              {/*check if user is logged in then display user profile, if not logged in then display login and register button */}
              {user != null ? (
                //  userProfile
                <div className="flex flex-col mx-auto pb-2 items-center bg-primary text-white">
                  <div className="relative">
                    <div className="absolute bottom-2 -right-3 z-10">
                      {/* <Button
                        variant="outline"
                        size="icon"
                        className="bg-muted  text-muted-foreground  rounded-md h-5 w-8 text-xs py-2"
                        onClick={openProfileEditModal}
                      >
                        Edit
                      </Button> */}
                    </div>
                    <Avatar
                      className="w-16 h-16 "
                      // onClick={openProfileEditModal}
                    >
                      {/* @ts-ignore */}
                      <AvatarImage
                        src={
                          process.env.NEXT_PUBLIC_IMAGES_URL + "/" + user?.image
                        }
                      />
                      {/* @ts-ignore */}
                      <AvatarFallback>{user?.name?.charAt(0)}</AvatarFallback>
                    </Avatar>
                  </div>
                  <div className="flex flex-col items-center my-2">
                    {/* @ts-ignore */}
                    <p className="font-bold text-base ">{user?.name}</p>
                    {/* @ts-ignore */}
                    <p className="text-sm font-normal"> {user?.email}</p>
                    {/* @ts-ignore */}
                    <p className="text-sm font-normal"> {user?.phone}</p>
                  </div>
                </div>
              ) : (
                //  login/register button
                <div className="flex md:flex-row flex-col  center w-full md:w-fit mx-auto my-8  border-primary gap-2 md:gap-x-3 p-4 md:p-0 ">
                  <Button
                    onClick={openLoginModal}
                    className="w-full md:w-auto p-3 hover:bg-primary  text-white "
                    variant={"default"}
                  >
                    Login
                  </Button>
                  <Button
                    onClick={openRegisterModal}
                    className=" w-full md:w-auto p-3 text-black  hover:bg-primary hover:text-white"
                    variant={"outline"}
                  >
                    Register
                  </Button>
                </div>
              )}

              <ul
                className={`text-center my-8 font-medium text-sm md:font-semibold flex flex-col gap-2 ${
                  isOpen ? "opacity-100" : "opacity-0"
                } `}
              >
                <Link href={"/myOrders"}>
                  {" "}
                  <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50">
                    {" "}
                    <Image
                      src={"/logos/orders.svg"}
                      alt="Orders icon"
                      height={20}
                      width={20}
                    />{" "}
                    <p>My Orders</p>
                  </li>
                </Link>
                {user != null && (
                  <Link href={"/myAddresses"}>
                    {" "}
                    <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50">
                      {" "}
                      <Image
                        src={"/logos/address.svg"}
                        alt="address icon"
                        height={20}
                        width={20}
                      />
                      <p>My Addresses</p>{" "}
                    </li>
                  </Link>
                )}
                <Link href={"/helpCenter"}>
                  {" "}
                  <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50">
                    {" "}
                    <Image
                      src={"/logos/help-center.svg"}
                      alt="help center icon"
                      height={20}
                      width={20}
                    />{" "}
                    <p>Help Center</p>
                  </li>
                </Link>
                <Link href={"/privacyPolicy"}>
                  {" "}
                  <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50">
                    <Image
                      src={"/logos/privacy-policy.svg"}
                      alt="privacy policy icon"
                      height={20}
                      width={20}
                    />{" "}
                    <p>Privacy Policy</p>{" "}
                  </li>
                </Link>
                <Link href={"/termsAndConditions"}>
                  {" "}
                  <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50">
                    {" "}
                    <Image
                      src={"/logos/terms-and-conditions.svg"}
                      alt="terms and conditions icon"
                      height={20}
                      width={20}
                    />{" "}
                    <p>Terms & Conditions</p>
                  </li>
                </Link>
                {user != null && (
                  <>
                    <li
                      onClick={handleLogout}
                      className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-gray-50 hover:cursor-pointer"
                    >
                      <Image
                        src={"/logos/logout.svg"}
                        alt="logout icon"
                        height={20}
                        width={20}
                      />
                      <p>Logout</p>
                    </li>
                    <li className="p-4 border-b border-gray-300 flex items-center justify-start gap-x-4 hover:bg-red-50 hover:cursor-pointer">
                      <Trash2 size={20} className="text-destructive" />
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <p className="w-full text-left text-destructive">
                            Delete Account
                          </p>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Are you absolutely sure?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This action cannot be undone. Your account will be
                              scheduled for deletion and permanently removed
                              after 30 days.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              className="bg-destructive hover:bg-destructive/80"
                              onClick={handleDeleteAccount}
                            >
                              Continue
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="flex-shrink-0 max-w-[70%]">
            <LocationComponent />
          </div>
        </div>

        <div
          className="flex-grow order-3 md:order-2 w-full md:w-auto md:max-w-3xl mt-3 md:mt-0  overflow-y-visible max-sm:z-40 z-[20] relative sm:mr-2"
          style={{
            marginRight:
              containerWidth >= 768 && containerWidth <= 1550
                ? `${margin_x}px`
                : "0.5rem",
          }}
        >
          <SearchBar data={data} />
        </div>
      </div>
    </section>
  );
};

export default Navbar;
