export function convertToCase(input: string, format: 'kebab' | 'camel' | 'snake'): string {
    const words = input
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '') // Remove non-alphanumeric characters except spaces and hyphens
        .split(/\s+/); 

    switch (format) {
        case 'kebab':
            return words.join('-');
        case 'camel':
            return words
                .map((word, index) => 
                    index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
                ).join('');
        case 'snake':
            return words.join('_');
        default:
            throw new Error(`Unknown format: ${format}`);
    }
}
