import { useState, useRef, useEffect } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";
import Chips from '@/components/shared/chips'
import { FaCheck } from 'react-icons/fa6';

const Variations = ({ variation_groups, baseVariation, setBaseVariation, checkedVariations, setCheckedVariations, checkedVariationIds, setCheckedVariationIds }) => {
  

  const [openGroupIndex, setOpenGroupIndex] = useState(0);
  const [submenuHeight, setSubmenuHeight] = useState(); 


  const toggleGroup = (index) => {
    // this will Close the previously opened group by resetting the height
    if (openGroupIndex === index) {
      setSubmenuHeight('0px');
      setOpenGroupIndex(null);
    } else {
      const newGroup = document.getElementById(`group-${index}`);
      if (newGroup) {
        setSubmenuHeight(`${newGroup.scrollHeight}px`);
      }
      setOpenGroupIndex(index);
    }
  };

  const handleCheckboxChange = (event, variation) => {
    if (baseVariation?.id === variation.id) {
      // setBaseVariation(null)
    } else {      
      setBaseVariation(variation)
    }
  };

  const handleChipsCheck = (event, variation) => {  
    const isAlreadyAdded = checkedVariationIds?.includes(variation.id)
    if (isAlreadyAdded) {
      setCheckedVariations(prevVariations => prevVariations.filter(item => item.id !== variation.id));
      setCheckedVariationIds(prevVariationIds => prevVariationIds.filter(id => id !== variation.id));
    } else {
      setCheckedVariations(prevVariations => [...prevVariations, variation]);
      setCheckedVariationIds(prevVariationIds => [...prevVariationIds, variation.id]);
    }  
  };

  // useEffect(() => {
  //   // when i select a base variation for first time, this code works fine. but when I select the next base variation, so it adds the next base variation along with the previous one in the checkedVariations array. how can I make the previously added base variation go away when another variation i selected. 
  //   if (baseVariation != null) {
  //     if (checkedVariationIds.includes(baseVariation.id)) {
  //       setCheckedVariations(prevVariations => prevVariations.filter(item => item.id !== baseVariation.id));
  //       setCheckedVariationIds(prevVariationIds => prevVariationIds.filter(id => id !== baseVariation.id));
  //     } else {
  //       setCheckedVariationIds(prevVariationIds => [...prevVariationIds, baseVariation.id])
  //       setCheckedVariations(prevVariations => [...prevVariations, baseVariation])
  //     }
  //   }
  // }, [baseVariation])



  return (
    <div className="w-full ">
      {/* <div className="flex items-center justify-between w-full rounded-full p-4 cursor-pointer">
        <h1 className="text-lg font-bold">Variations</h1>
      </div> */}
      <div className="transition-height duration-300 ease-in-out mx-2 lg:mx-4">
        {variation_groups && variation_groups.map((group, index) => (
          <div key={group.id} className="">
            {/* Group header code */}
            <div className="flex items-center justify-between rounded-full bg-gray-100 p-3 cursor-pointer mt-2" onClick={() => toggleGroup(index)}>
              <h2 className="text-md font-semibold">{group.title}</h2>
              <RiArrowDropDownLine className={`w-4 h-4 transition-transform ${openGroupIndex === index ? 'rotate-180' : ''}`} />
            </div>
            {/* Submenu div with dynamic height based on state */}
            <div id={`group-${index}`} style={{ height: openGroupIndex === index ? submenuHeight : '0px' }} className=" max-h-[15rem] flex flex-col overflow-y-scroll scrollbar-hidden duration-500 rounded-lg mx-2 lg:mx-4">
              {group.selection == 1 ? (
                group.variations && group.variations.map(option => (
                  <label key={option.id} className="flex items-center justify-between mt-3">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(event) => handleCheckboxChange(event, option)}
                        checked={baseVariation === option}
                      />
                      <span>{option?.name}</span>
                    </div>
                    <span>+Rs {option.price}</span>
                  </label>
                ))
              ) : (
                <div className='flex flex-wrap gap-4 pt-4'>
                  {group.variations && group.variations.map(option => (
                    <button key={option.id} onClick={(event) => { handleChipsCheck(event, option) }} className={`rounded-full px-4 py-2 border w-fit border-primary flex center gap-x-2 ${checkedVariationIds.includes(option.id) ? "bg-primary text-white font-bold" : ""}`}>
                      { checkedVariationIds.includes(option.id) && <div><FaCheck /></div>}
                      <p>{option?.name}</p>
                      <p className='text-xs'>+Rs {option.price}</p>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

export default Variations;
