"use client"
import React, { useState } from 'react'
import Modal from './wrappers/modal'
import MyPrimaryButton from './buttons/myPrimaryButton'
import { useAppSelector } from '@/lib/hooks'
import { toast, Toaster } from 'sonner'
import { updateProfile, uploadImage } from '@/lib/apiRequests'
import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
import { Button } from './buttons/button'
import Input from './myInput'


interface Props {
    closeProfileEditModal: () => void
}

const ProfileEditModal = ({ closeProfileEditModal }: Props) => {

    //@ts-ignore
    const { user } = useAppSelector((state) => state?.user)
    const [editProfile, setEditProfile] = useState({
        // @ts-ignore
        name: user?.name,
        // @ts-ignore
        image: user?.image || null // Load current image if available
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setEditProfile({
            ...editProfile,
            [name]: value,
        });
    };

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        setEditProfile({
            ...editProfile,
            image: file, // Store the actual file in the state
        });
    };

    const handleSaveChanges = async () => {
        let uniqueImageName;

        const formData1 = new FormData();

        if (editProfile.image instanceof File) {
            // Generate a unique name for the image
            const imageExtension = editProfile.image.name.split('.').pop(); // Get the file extension
            uniqueImageName = `${uuidv4()}.${imageExtension}`; // Generate a unique name

            // Rename the file in the FormData
            const renamedFile = new File([editProfile.image], uniqueImageName, { type: editProfile.image.type });
            formData1.append("image", renamedFile);
        }

        const imageUploadRes = await uploadImage(formData1);
        if (imageUploadRes.error) {
            toast.error(`Image upload failed: ${imageUploadRes.error_message}`);
            return;
        }

        const formData2 = new FormData();
        formData2.append("name", editProfile.name);
        // @ts-ignore
        formData2.append("email", user?.email);
        formData2.append("image", uniqueImageName!); // Use the unique image name for the profile update

        const profileUpdateRes = await updateProfile(formData2);
        if (profileUpdateRes.error) {
            toast.error(`Profile Update failed: ${profileUpdateRes.error_message}`);
            return;
        }

        toast.success('Profile updated successfully');
    };

    const triggerImageUpload = () => {
        document.getElementById('image-input')?.click();
    };

    const getImageSrc = () => {
        if (typeof editProfile.image === 'string') {
            return `${process.env.NEXT_PUBLIC_IMAGES_URL}/${editProfile.image}`;
        } else if (editProfile.image instanceof File) {
            return URL.createObjectURL(editProfile.image);
        } else {
            return '/placeholder-image.jpg';
        }
    };

    return (
        <div>
            <Toaster richColors position="top-center" />
            <Modal closeModal={closeProfileEditModal} title='Edit Profile'>
                <>
                    <div className=' px-4'>
                        <div className="space-y-2 text-center">
                            <div className="relative w-32 h-32 mx-auto">
                                <img
                                    src={getImageSrc()} // Use the conditional function to get the correct image source
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            </div>
                            {/* <MyPrimaryButton text="Upload Image" onClickFunction={triggerImageUpload} /> */}
                            <Button className='px-3 py-2 text-xs font-normal rounded-lg' variant='ghost' onClick={triggerImageUpload}>
                                Change Image
                            </Button>
                            <input
                                type="file"
                                id="image-input"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="hidden"
                            />
                            <div className='space-y-1 text-start'>
                                <label htmlFor="name" className="block text-sm text-muted-foreground  ">
                                    Name
                                </label>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={editProfile.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <Button className='bg-primary text-white  text-xs mt-2 ' onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default ProfileEditModal
