"use client";

import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { fetchAndSetCurrentLocation } from "./initialModal";

export default function LocationInitializer() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.user);
  const currentAddress = useAppSelector(
    (state: any) => state.location.currentAddress
  );
  const hasInitialized = useRef(false);

  useEffect(() => {
    const initializeLocation = async () => {
      if (!hasInitialized.current && (!currentAddress || Object.keys(currentAddress).length === 0)) {
        try {
          hasInitialized.current = true;
          await fetchAndSetCurrentLocation(dispatch, user);
        } catch (error) {
          console.error("Error initializing location:", error);
          hasInitialized.current = false; // Reset on error to allow retry
        }
      }
    };

    initializeLocation();
  }, []); // Remove dependencies to run only once on mount

  return null; // This component doesn't render anything
} 