import Image from 'next/image'
import Link from 'next/link';
import React from 'react'
import { FaRegHeart } from 'react-icons/fa6';
import { IoIosTimer } from "react-icons/io";
import AddToCart from './buttons/addToCart';
import PlusButton from '../shared/buttons/plusButton';
import { Card, CardContent, CardFooter, CardTitle } from './card';
import { Button } from './buttons/button';
import { HeartIcon, PlusIcon } from 'lucide-react';

const FoodItemCard = ( props : Product) => {

const { id, name, price, product_description, images, iscart, categories_id, variations } = props
    const imageURL = images[0]?.image
    // Split the URL by '/'
    // const parts = imageUrl.split('/');
    // // Get the last part which should be the image name
    // const imageName = parts[parts.length - 1];
    // const imageURL = `${process.env.NEXT_PUBLIC_IMAGES_URL}${imageName}`


    return (

        // <Card className=' my-5 flex flex-col gap-y-2  '>
        //     <CardContent>

        //     <div className='relative'>
        //         <Image src={imageURL} alt='food image' height={999} width={999} unoptimized className='h-40 w-full object-cover rounded-lg' />
        //         <FaRegHeart className={`absolute top-2 right-2 text-red-500 text-xl`} />
        //     </div>
        //     <CardTitle>{name}</CardTitle>

        //     <div className='flex justify-between'>
        //         <div>
        //             <div className='flex '>
        //                 <p className='font-bold text-primary text-lg md:text-xl'>Rs {price}</p>
        //             </div>
        //         </div>
        //         <div className=''>
        //             <PlusButton    {...props} />
        //         </div>
        //     </div>
        //     </CardContent>

        // </Card>
        <Card className="flex flex-col h-full hover:shadow-lg transition-shadow duration-300">
        <CardContent className="text-center flex-grow p-0">

          <Image
            src={imageURL}
            alt="Food Image"
            className="mx-auto mb-2 w-full h-36 object-cover rounded-lg"
            width="150"
            height="150"
            style={{ aspectRatio: "150/150", objectFit: "cover" }}
          />
          <div className='px-2 md:px-4 flex flex-col items-start '>
          <h3 className="text-sm md:text-lg font-medium md:font-semibold text-faded600 text-start">{name}</h3>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between pb-2 px-2 md:px-4">
          <p className="md:text-lg text-sm  text-primary md:font-normal"  unselectable='on'>Rs {price}</p>
            <Button variant="ghost" size="icon">
              <PlusButton {...props}  />
            </Button>
          </CardFooter>
      </Card>
    )
}

export default FoodItemCard
