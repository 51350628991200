'use client'
import { useAppDispatch } from '@/lib/hooks'
import { add } from '@/redux/slices/cartSlice'
import React, { Dispatch, SetStateAction } from 'react'


interface Props {
    id: number | undefined,
    name: string | undefined,
    itemPrice: number | undefined,
    variationsTotalPrice: number ,
    categories_id: number | undefined,
    variations: Variation[] | null,
    variationIds: number[] | null,
    iscart: number | undefined,
    image: string | undefined,
    quantity: number | undefined,
    setQuantity?: Dispatch<SetStateAction<number>>
    color?: string
    closeModal? : () => void
}

const AddToCart = ({ id, name, itemPrice, variationsTotalPrice, variations, variationIds, categories_id, quantity, iscart, image, setQuantity, color, closeModal }: Props) => {

    const dispatch = useAppDispatch()
    
    const addProductToCart = (product: CartProduct) => {
        dispatch(add(product))
        if (setQuantity) {
            setQuantity(1)
        }
        // close the product detail Modal
        !!closeModal && closeModal()
    }


    // calculating total price (item price + selected variations price)
    // @ts-ignore
    const totalPrice = variationsTotalPrice ? variationsTotalPrice + Number(itemPrice) : itemPrice
    return (
        <>
            <button onClick={() => addProductToCart({ id, name, itemPrice, totalPrice, variations, variationIds, categories_id, quantity, iscart, image })}
                className={`-mb-4  bg-primary text-white px-4 py-2 rounded-full ${quantity === 0 ? "cursor-not-allowed" : "cursor-pointer"} ${color == 'red' ? "bg-red-500 px-3 rounded-lg" : ""}`}
            >
                Add to cart
            </button>
        </>
    )
}

export default AddToCart