// @ts-nocheck

'use client'
"use strict"
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { addSavedAddress, setCurrentAddress, setInitialLocation, setNearestBranch } from '@/redux/slices/locationSlice';
import { FiPlus } from 'react-icons/fi';
import Modal from '@/components/shared/wrappers/modal';
import { getAddress, getNearestBranch, addNewAddress, showAddresses } from '@/lib/endpoints';
import { Toaster, toast } from 'sonner';
import Map from '@/components/shared/map';
import { useAuthModal } from '@/app/authModalProvider';
import { useAddAddressModal } from '@/app/addAddressModalProvider';
import { MdOutlineLocationOn } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library
import { Button } from '../shared/buttons/button';
import LoaderSpinner from '../shared/loader';
import { Locate, MapPin } from 'lucide-react';

const LocationComponent = ({ addNewAddressModal, setAddNewAddressModal }: { addNewAddressModal?: boolean, setAddNewAddressModal?: () => void }) => {
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { user } = useAppSelector((state) => state.user);
  //@ts-ignore
  const initialLocation = useAppSelector((state) => state.location.initialLocation);
  //@ts-ignore
  const currentAddress = useAppSelector((state) => state.location.currentAddress);
  const { modalType, openLoginModal, openRegisterModal } = useAuthModal();
  const { addAddressModal, setAddAddressModal, initialModal, setInitialModal } = useAddAddressModal(); // state to control the on/off of the map to add new address

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [mapLocation, setMapLocation] = useState({
    lat: initialLocation?.location?.latitude,
    lng: initialLocation?.location?.longitude
  } || null);
  const [mapAddress, setMapAddress] = useState(initialLocation?.address ?? "");
  const [myAddress, setMyAddress] = useState("");
  const [title, setTitle] = useState("");
  const [mapCity, setMapCity] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchLocationData = async (latitude: number, longitude: number) => {
      try {
        // console.log("longitude: ", longitude , "latitude: ", latitude)
        // console.log("step 1")
        // console.log("key: " , process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY)
        // Fetch address
        let formdata1 = new FormData();
        formdata1.append("lat", latitude.toString().trim());
        formdata1.append("lon", longitude.toString().trim());
        formdata1.append("key", process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY!);

        // console.log("step 2")

        const addressResponse = await fetch(getAddress, {
          method: 'POST',
          body: formdata1
        });
        // console.log("step 3")
        if (!addressResponse.ok) {
          console.error("an error occured fetching address: ", addressResponse.statusText);
          // throw new Error(addressResponse.error_message);
        }
        const addressData = await addressResponse.json();
        const address = addressData?.address;

        dispatch(setInitialLocation({
          location: { latitude, longitude },
          address
        }))
      } catch (error) {
        console.error('Fetching location data failed', error);
        // Optionally, you could dispatch an error state to Redux or handle it as needed
      }
    };

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocationData(latitude, longitude);
        },
        (error) => {
          console.error('Geolocation error:', error);
          // Optionally, dispatch a failure state or handle it as needed
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      // Optionally, handle the geolocation not supported case
    }

  }, [dispatch]);

  useEffect(() => {
    if (addNewAddressModal) {
      setAddAddressModal(true)
    }
  }, [addNewAddressModal])



  const handleAddAddress = async () => {
    // if (!mapLocation || myAddress) {
    //   toast.error("please select a location on map")
    //   return
    // }
    setLoading(true)
    if (user === null) {
      const uniqueId = uuidv4(); // Generate a unique ID
      dispatch(addSavedAddress({
        id: uniqueId,
        location: {
          latitude: mapLocation?.lat,
          longitude: mapLocation?.lng
        },
        address: myAddress,
        gpsAddress: mapAddress,
        title: title,
        city: mapCity
      }));

      dispatch(setCurrentAddress({
        id: uniqueId,
        location: {
          latitude: mapLocation?.lat,
          longitude: mapLocation?.lng
        },
        address: myAddress,
        gpsAddress: mapAddress,
        title: title,
        city: mapCity
      }))
      toast.success('Address added successfully!');
      setLoading(false)


    } else {
      try {
        let formData = new FormData();
        formData.append("location", `${mapLocation.lat},${mapLocation.lng}`);
        formData.append("address", myAddress);
        formData.append("gps_address", mapAddress);
        formData.append("title", title);
        formData.append("city", mapCity);
        formData.append("user_phone", user?.phone);

        const res = await fetch(addNewAddress, {
          method: 'POST',
          body: formData,
        });

        const data = await res.json();

        if (data?.error) {
          setLoading(false)
          toast.error(`Error adding address: ${data.error_msg}`);
          console.error('Error adding address:', data.error_msg);
        } else {
          setLoading(false)

          toast.success('Address added successfully!');
          console.log('Address added successfully:', data);

          // Close the modal
        }
      } catch (error) {
        setLoading(false)
        console.error('Error adding address:', error);
        toast.error('An unexpected error occurred while adding the address. Please try again.');
      }

      if (setAddNewAddressModal) {

        setAddNewAddressModal()
      }
    }

    setAddAddressModal(false);
    if (initialModal && currentAddress) {
      setInitialModal(false);
    }
  };


  const handleClick = () => {
    openModal()

  }

  const openModal = () => setAddAddressModal(true);
  const closeModal = () => {
    setAddAddressModal(false)
    if (setAddNewAddressModal) {
      setAddNewAddressModal()
    }
  };

  return (
    <div className='flex items-center'>
      <Toaster richColors position="top-center" />
      {/* <div onClick={handleClick} className='bg-primary rounded-full p-2 text-white flex center'><FiPlus /></div> */}

      {!addNewAddressModal && (currentAddress?.address ? (
        <div onClick={() => setInitialModal(true)} className='flex items-center gap-x-2 cursor-pointer p-2 rounded-lg hover:bg-neutral-100  transition-all ease-in-expo duration-300 overflow-hidden'>
          <MapPin className='md:size-6 size-5 flex-shrink-0 text-primary' />
          <div className='overflow-hidden '>
            <p className='text-primary font-bold text-sm truncate'>{currentAddress?.title}</p>
            <p className='text-xs text-faded truncate'>{currentAddress?.address}</p>
          </div>
        </div>
      ) : (
        <Button onClick={() => setInitialModal(true)} variant={"ghost"} className='font-bold text-sm cursor-pointer text-faded hover:bg-neutral-100 transition-all ease-in-expo duration-300'><MdOutlineLocationOn className='size-6' /> Choose your Location for Delivery</Button>
      ))}


      {addAddressModal && (
        <Modal closeModal={closeModal} title='Add New Address'>
          <div className='w-full max-w-5xl flex flex-col'>

            <div className='w-full '>
              {/* <h3 className='text-start text-foreground font-bold text-xl md:text-2xl mb-1 md:mb-12'>Add a New Address</h3> */}
            </div>
            <div className='md:mt-4 grid grid-cols-1 lg:grid-cols-2 gap-x-2 '>
              <form className='flex flex-col md:my-2' onSubmit={(e) => {
                e.preventDefault();
                handleAddAddress();
              }}>
                <div className="gap-x-3">
                  <label htmlFor="title" className="block text-faded600 font-normal mb-2 text-sm">
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Enter Title, e.g., Office"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="mb-2 md:mb-5 rounded-lg bg-gray-200 py-3 px-4 w-full"
                  />

                  <label htmlFor="myAddress" className="block text-faded600 font-normal mb-2 text-sm">
                    My Address
                  </label>
                  <input
                    type="text"
                    id="myAddress"
                    name="myAddress"
                    placeholder="Enter your street address, e.g., house no, flat no, street no."
                    value={myAddress}
                    onChange={(e) => setMyAddress(e.target.value)}
                    required
                    className="mb-2 md:mb-5 rounded-lg bg-gray-200 py-3 px-4 w-full"
                  />

                  <label htmlFor="location" className="block text-faded600 font-normal mb-2 text-sm">
                    Your Map Location
                  </label>
                  <div
                    id="location"
                    className="mb-2 md:mb-5 rounded-lg bg-gray-200 py-3 px-4 w-full text-gray-700"
                    style={{ userSelect: 'none' }}
                  >
                    {mapAddress}
                  </div>
                </div>

                <Button type='submit' disabled={!title || !mapAddress} className='text-white md:w-36 font-bold disabled:cursor-not-allowed mb-4 md:mb-auto'>
                  {!loading ?
                    <span>Add Address</span>
                    :
                    <LoaderSpinner />
                  }
                </Button>

              </form>
              <div className='flex justify-center items-center w-full'>
                <Map setMapAddress={setMapAddress} setMapCity={setMapCity} location={mapLocation} setLocation={setMapLocation} />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* <div className='lg:min-w-72'>
        <Dropdown  phoneNumber={user?.phone} isDropDownOpen={isDropDownOpen} setIsDropDownOpen={setIsDropDownOpen} />
      </div> */}
    </div>
  );
};



export default LocationComponent;
