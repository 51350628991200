'use client'
import { useEffect, useMemo, useRef, useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import FoodItemCard from "../shared/foodItemCard";
import { Button } from "../shared/buttons/button";
import Link from "next/link";
import { convertToCase } from "@/utils/convert-case";

interface Menu2Props {
    data: Category[];
}

const Menu2 = ({ data }: Menu2Props) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);
    const mainRef = useRef<HTMLDivElement>(null);
    const categoryRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const buttonRefs = useRef<{ [key: number]: HTMLButtonElement | null }>({});

    const categories = useMemo(() => data?.map(record => ({
        id: record.id,
        name: record.name,
        active: record.active,
        data: record.data,
    })), [data]);

    useEffect(() => {
        const firstActiveCategory = categories?.find((category: Category) => Number(category.active) === 1);
        if (firstActiveCategory) {
            setActiveCategory(firstActiveCategory);
        }
    }, [categories]);

    const handleScroll = (direction: "left" | "right") => {
        if (mainRef.current) {
            const scrollAmount = 200;
            mainRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth"
            });
        }
    };

    const checkScrollButtons = () => {
        if (mainRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = mainRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft + clientWidth < scrollWidth - 1);
        }
    };

    useEffect(() => {
        checkScrollButtons();
        if (mainRef.current) {
            mainRef.current.addEventListener('scroll', checkScrollButtons);
        }
        return () => {
            if (mainRef.current) {
                mainRef.current.removeEventListener('scroll', checkScrollButtons);
            }
        };
    }, []);

    useEffect(() => {
        if (mainRef.current) {
            const { scrollWidth, clientWidth } = mainRef.current;
            setShowRightButton(scrollWidth > clientWidth);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                let currentActiveEntry: any = null;
                let firstIntersectingEntry: any = null;

                entries.forEach((entry) => {
                    const categoryId = Number(entry.target.getAttribute("data-category-id"));

                    if (categoryId === activeCategory?.id) {
                        currentActiveEntry = entry;
                    }

                    if (entry.isIntersecting && !firstIntersectingEntry) {
                        firstIntersectingEntry = entry;
                    }
                });

                if (!currentActiveEntry?.isIntersecting && firstIntersectingEntry) {
                    const intersectingCategory = categories.find(
                        category => category.id == Number(firstIntersectingEntry.target.getAttribute("data-category-id"))
                    );

                    if (intersectingCategory) {
                        setActiveCategory(intersectingCategory);
                    }
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        );

        Object.values(categoryRefs.current).forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            Object.values(categoryRefs.current).forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [categories, activeCategory, categoryRefs]);

    useEffect(() => {
        if (activeCategory && buttonRefs.current[activeCategory.id] && mainRef.current) {
            const button = buttonRefs.current[activeCategory.id];
            const buttonRect = button!.getBoundingClientRect();
            const containerRect = mainRef.current.getBoundingClientRect();

            if (buttonRect.left < containerRect.left) {
                // Button is out of view on the left, scroll it into view
                mainRef.current.scrollBy({
                    left: buttonRect.left - containerRect.left - 20, // small padding adjustment
                    behavior: 'smooth'
                });
            } else if (buttonRect.right > containerRect.right) {
                // Button is out of view on the right, scroll it into view
                mainRef.current.scrollBy({
                    left: buttonRect.right - containerRect.right + 20, // small padding adjustment
                    behavior: 'smooth'
                });
            }
        }
    }, [activeCategory]);

    const handleCategoryClick = (category: Category) => {
        setActiveCategory(category); // This ensures the active category is updated
        // categoryRefs.current[category.id]?.scrollIntoView({ behavior: "smooth" });

    
    };

    return (
        <section className="max-w-[1440px] mx-auto">
            <div className="flex items-center gap-x-2 mx-4 my-4 py-2 sticky top-0 z-10 bg-white ">
                {showLeftButton && (
                    <button onClick={() => handleScroll("left")} className="z-10">
                    <MdArrowBackIos className={`${showLeftButton ? "text-primary":"text-muted-foreground "} font-bold`} />
                    </button>
                )}
                <div ref={mainRef} className={`flex lg:justify-center gap-x-4 w-full mx-auto overflow-x-scroll scrollbar-none ${showLeftButton ? 'pl-4': 'ml-0 pl-0'}`}>
                    {categories?.map((category, index) => (
                        <Link key={index} href={`#${convertToCase(category.name, 'kebab')}`} passHref>
                        <Button
                            key={index}
                            ref={el => {
                                buttonRefs.current[category.id] = el;
                            }}
                            className={`${category.name === activeCategory?.name ? "text-white" : "text-muted-foreground"} text-xs md:text-sm whitespace-nowrap`}
                            variant={category.name === activeCategory?.name ? "default" : "outline"}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category.name}
                        </Button>
                        </Link>
                    ))}
                </div>
                {showRightButton && (
                    <button onClick={() => handleScroll("right")} className="z-10">
                        <MdArrowForwardIos className="text-primary font-bold" />
                    </button>
                )}
            </div>

            {
                data?.map((category) => (
                    <div key={category?.id} id={convertToCase(category.name, 'kebab')}  ref={(el: HTMLDivElement | null) => {
                        if (el) categoryRefs.current[category?.id] = el;
                    }} data-category-id={category?.id} className="mb-6 scroll-smooth">
                        <h2 className='my-4 mx-4 text-neutral-700 text-sm md:text-lg font-bold  md:font-extrabold '>{category.name}</h2>
                        <div className='grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4 mx-4'>
                            {category?.data!.map((item: Product, index) => (
                                <FoodItemCard key={index} {...item} />
                            ))}
                        </div>
                    </div>
                ))}
        </section>
    );
};

export default Menu2;