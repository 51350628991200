

const Loading = () => {
    return (
        <div className="w-full">
            <div className="grid grid-cols-[30%,70%] gap-x-2 mb-4">
                <div className=' size-20   rounded-md simplegradient shimmer overflow-hidden ' />
                <div>
                    <div className='mb-6 w-full h-[16px]  rounded-md simplegradient shimmer overflow-hidden ' />
                    <div className=' w-1/2 h-[16px]   rounded-md simplegradient shimmer overflow-hidden ' />
                </div>
            </div>
            <div className="grid grid-cols-[30%,70%] gap-x-2">
                <div className='size-20   rounded-md simplegradient shimmer overflow-hidden ' />
                <div>
                    <div className='mb-6 w-full h-[16px]  rounded-md simplegradient shimmer overflow-hidden ' />
                    <div className=' w-1/2 h-[16px]   rounded-md simplegradient shimmer overflow-hidden ' />
                </div>
            </div>

        </div>
    )
}

export default Loading