//@ts-nocheck

"use client";
import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { getAddress } from "@/lib/endpoints";
import { useAppSelector } from "@/lib/hooks";

interface MapProps {
  setMapAddress: (address: string) => void;
  location: { lat: number; lng: number } | null;
  setLocation: (location: { lat: number; lng: number }) => void;
  setMapCity: (city: string) => void;
  size?: { width: string; height: string };
  showSearchBox?: boolean;
}

function Map({setMapAddress, location, setLocation, setMapCity , size, showSearchBox = true  }: MapProps) {
  //@ts-ignore
  const initialLocation = useAppSelector((state) => state.location.initialLocation);
  const zoomValue = 16
  const [center, setCenter] = useState(() => {
    if (initialLocation && initialLocation.location) {
      return {
        lat: initialLocation.location.latitude,
        lng: initialLocation.location.longitude,
      };
    }
    return { lat: -34.397, lng: 150.644 }; // Default value if initialLocation is not available
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
    libraries: ["places"],
  });
  const searchBoxRef = useRef(null);


  const mapStyles = {
    width: size?.width || "500px",
    height: size?.height || "350px",
    position: 'relative' as const,
  };
  const onPlacesChanged = useCallback(() => {

    //@ts-ignore
    const places = searchBoxRef.current?.getPlaces();
    if (places?.length === 0) return;

    const place = places?.[0];
    const lat = place?.geometry.location.lat();
    const lng = place?.geometry.location.lng();
    setLocation({ lat, lng });
    setCenter({ lat, lng });

    const formData = new FormData();
    formData.append("lat", lat?.toString().trim());
    formData.append("lon", lng?.toString().trim());
    formData.append("key", process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY!);



    fetch(getAddress, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((addressData) => {
        if (addressData) {
          setMapAddress(addressData.address);
          setMapCity(addressData.city);
        }
      })
      .catch((error) => console.error("Error fetching address:", error));
  }, [setLocation, setCenter, setMapAddress, setMapCity]);

  const onMapClick = useCallback(
    async (event: any) => {

      // if (event.latLng.lat)
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      setLocation({ lat, lng });
      setCenter({ lat, lng });

      const formData = new FormData();
      formData.append("lat", lat.toString().trim());
      formData.append("lon", lng.toString().trim());
      formData.append("key", process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY!);

      try {
        const addressResponse = await fetch(getAddress, {
          method: "POST",
          body: formData,
        });
        const addressData = await addressResponse.json();
        if (addressData) {
          setMapAddress(addressData.address);
          setMapCity(addressData.city);
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    },
    [setLocation, setCenter, setMapAddress, setMapCity]
  );
  const handleLocationData = async (lat: number, lng: number) => {
    const formData = new FormData();
    formData.append("lat", lat.toString().trim());
    formData.append("lon", lng.toString().trim());
    formData.append("key", process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY!);

    try {
      const addressResponse = await fetch(getAddress, {
        method: "POST",
        body: formData,
      });
      const addressData = await addressResponse.json();
      if (addressData) {
        setMapAddress(addressData.address);
        setMapCity(addressData.city);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      console.log("Getting current location");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          setCenter({ lat: latitude, lng: longitude });
          handleLocationData(latitude, longitude);

        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  }, [setLocation, setCenter]);
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div style={mapStyles}>
        {showSearchBox && <StandaloneSearchBox
        //@ts-ignore
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search for places"
          className="box-border border border-gray-300 w-72 h-10 px-3 rounded-md  text-sm absolute top-2 md:-top-12 left-[40%] md:left-1/2 transform -translate-x-1/2 z-10 bg-white"
        />
      </StandaloneSearchBox>}
      <GoogleMap
        mapContainerClassName="w-full h-full rounded-md"
        center={center}
        zoom={zoomValue}
        onClick={onMapClick}
        options={{
          mapTypeControl: false, // Disable map and satellite buttons
          streetViewControl: false, // Optionally disable Street View control
        }}
      >
        {location && <Marker position={location} />}
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);
