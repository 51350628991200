'use client'
import { usePathname, useRouter } from 'next/navigation'
import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import FoodItemCard2 from './foodItemCard'
import useDebounce from '@/components/shared/debounce'
import Image from 'next/image'
import PlusButton from './buttons/plusButton'
import TailwindLoader from './tailwindLoader'
import { IoIosCloseCircle } from 'react-icons/io'
import Loading from './searchBarProductShimmer'

const SearchBar = ({ data }: any) => {
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    //   const pathname = usePathname()
    const debouncedSearch = useDebounce(search, 500, setLoading); // 2 seconds delay

    const filteredProducts = data?.flatMap((category: any) =>
        category.data.filter((item: Product) =>
            item?.name.toLowerCase().includes(debouncedSearch.toLowerCase())
        )
    );

    const handleSearch = (e: any) => {
        e.preventDefault()
        router.push(`/search?name=${search}`)
        setSearch("")
    }

    return (
        <>
            <div className='flex justify-between bg-gray-200 gap-x-1 h-full  items-center border-2 border-gray-200 rounded-2xl w-full px-4 py-2'>
                <BiSearch className='h-7 w-7 text-faded' />
                <input
                    value={search}
                    type="search "
                    className='focus:outline-none px-2 bg-gray-200 text-sm h-full w-full'
                    placeholder='Search for food'
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch(e)
                        }
                    }} />
                <div className='flex center gap-x-3'>
                    {search && <IoIosCloseCircle className='h-12 w-12  p-2 rounded-full text-red-500' onClick={() => setSearch("")} />}
                    {/* <BiSearch className='h-8 w-8 bg-secondary p-2 rounded-full text-white' onClick={handleSearch} /> */}
                </div>
            </div>

            {search.length > 0 && <div className='absolute z-10 flex flex-col items-center justify-between   w-full max-h-60 bg-gray-100 rounded-lg  gap-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-inherit  p-4'>
                {/* products related to searched keyword will be shown here */}

                {loading === true ? (
                    <>
                        {/* <TailwindLoader /> */}
                        <Loading/>
                    </>
                ) : (
                    <>
                        {
                            filteredProducts.length > 0 ?
                                (
                                    filteredProducts?.map((item: Product, index: number) => (
                                        <div key={index} className='flex items-center justify-between gap-x-4 py-1 px-4 w-full text-sm rounded-lg shadow-lg' >
                                            <div className='flex items-center gap-x-4'>
                                                <Image src={item.images[0]?.image} alt="product image" width={100} height={100} className='size-20 object-cover rounded-lg' />
                                                <div className='flex flex-col '>
                                                    <p className='font-bold text-primary'>{item.name}</p>
                                                    <p>Rs {item.price}</p>
                                                </div>
                                            </div>
                                            <PlusButton {...item} setSearch={setSearch}  />
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <p className='font-bold text-black'>No products found</p>
                                    </>
                                )
                        }
                    </>
                )}

            </div>}
        </>
    )
}

export default SearchBar
