'use client'
import React, { useEffect, useState } from 'react'
import Modal from '../wrappers/modal'
import Image from 'next/image'
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci'
import AddToCart from './addToCart'
import Variations from '../variations'
import { useAppDispatch, useAppSelector } from '@/lib/hooks'
import { compareVariations } from '@/lib/helperFunctions'
import { decreaseProductQuantity, increaseProductQuantity, remove } from '@/redux/slices/cartSlice'
import { add } from '@/redux/slices/cartSlice'
import { HeartIcon, PlusIcon, ShoppingCart } from 'lucide-react'
import { Button } from './button'

interface PlusButtonProps extends Product {
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
}
const PlusButton = (props: PlusButtonProps) => {
    const { id, name, price, product_description, images, iscart, categories_id, variations, variation_groups, setSearch } = props

    // @ts-ignore
    const preSelectedBaseVariation = variation_groups.find(group => group.selection === "1")?.variations[0];

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [baseVariation, setBaseVariation] = useState(preSelectedBaseVariation)
    const [checkedVariations, setCheckedVariations] = useState<Variation[]>([]);
    const [checkedVariationIds, setCheckedVariationIds] = useState([])
    const [quantity, setQuantity] = useState(1)
    let [variationsTotalPrice, setVariationsTotalPrice] = useState(0)
    //@ts-ignore
    const { cart } = useAppSelector((state) => state.allCart)
    const dispatch = useAppDispatch()
    // to check is product with same variations is already in cart or not. if true then only increase/decrease quantity button will be shown instead of "add to cart" button
    const [isProductAlreadyInCart, setIsProductAlreadyInCart] = useState(false)
    // quantity of that specific product in cart which is currently opened and has same variations
    const [productQuantityInCart, setProductQuantityInCart] = useState(0)
    // the index of that product will be used to increase/decrease quantity in cart because we can't use only id as there will be same product with multiple variations so id will be same for both
    const [productIndexInCart, setProductIndexInCart] = useState<number | null>(null)

    useEffect(() => {
        // Check if the cart array is empty
        if (cart.length === 0) {
            setIsProductAlreadyInCart(false);
            return; // Exit the useEffect early
        }

        cart.map((product: any, index: number) => {
            if (product.id === id) {
                // if product is already in cart, then compare the variations of that product with the variations that we have checked
                if (compareVariations(product.variationIds, [...checkedVariationIds, baseVariation?.id])) {
                    setIsProductAlreadyInCart(true);
                    setProductQuantityInCart(product?.quantity!);
                    setProductIndexInCart(index);
                } else {
                    setIsProductAlreadyInCart(false);
                }
            } else {
                setIsProductAlreadyInCart(false);
            }
        });
    }, [checkedVariationIds, baseVariation, cart])

    // calculating total price of all selected variations
    useEffect(() => {
        let total = 0;

        if (baseVariation) {
            total += Number(baseVariation.price);
        }
        if (checkedVariations.length > 0) {
            total += checkedVariations.reduce((sum, variation) => {
                return sum + Number(variation.price);
            }, 0);
        }
        setVariationsTotalPrice(total);
    }, [checkedVariations, baseVariation]);


    const handleClick = () => {
        if (variation_groups && variation_groups.length > 0) {
            openModal()
        } else {
            // initializing these values just to pass them to function if no variations are available for a product
            const itemPrice = price
            const totalPrice = price
            const image = images[0].image
            const variations: any = []
            const variationIds: any = []

            addProductToCart({ id, name, quantity, itemPrice, totalPrice, image, categories_id, iscart, variations, variationIds })
        }
        if (setSearch) {
                 setSearch("")
             }
    }


    const addProductToCart = (product: CartProduct) => {
        dispatch(add(product))
        if (setQuantity) {
            setQuantity(1)
        }
        // close the product detail Modal
        !!closeModal && closeModal()
    }


    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
    }

    const increaseQuantity = (id: number) => {
        dispatch(increaseProductQuantity(id))
    }

    const decreaseQuantity = (id: number) => {
        dispatch(decreaseProductQuantity(id))
    }

    const removeProductFromCart = (id: number) => {
        dispatch(remove(id))
    }



    return (
        <div>
            <Button className='w-8 h-8 md:w-10 md:h-10 flex center ' size={"icon"} variant={"outline"} onClick={handleClick}>
            <PlusIcon  className="text-destructive cursor-pointer" />
            </Button>



            {isModalOpen && (
                <Modal closeModal={closeModal}>
                    <div className='max-w-5xl grid grid-cols-1  md:grid-cols-2 gap-4'>
                        <div className='max-lg:mx-auto'>
                            <Image src={images[0].image} alt='product image' height={500} width={500} unoptimized className='size-[14rem] lg:size-[28rem] object-cover' />
                        </div>
                        <div className='flex flex-col justify-between gap-4 md:gap-2'>

                            <div className='max-lg:text-center'>
                                <h3 className='font-bold text-2xl md:text-3xl mb-1'>{name}</h3>
                                <p className='text-gray-600'>This is the description of the product</p>
                            </div>
                            <div>
                                {isProductAlreadyInCart && <p className='text-red-500'>Product with same variations is already in cart!</p>}
                            </div>
                            <div>
                                <Variations
                                    variation_groups={variation_groups}
                                    baseVariation={baseVariation}
                                    setBaseVariation={setBaseVariation}
                                    checkedVariations={checkedVariations}
                                    setCheckedVariations={setCheckedVariations}
                                    checkedVariationIds={checkedVariationIds}
                                    setCheckedVariationIds={setCheckedVariationIds}
                                />
                            </div>

                            {!isProductAlreadyInCart ?
                                (
                                    <div className='flex justify-between gap-x-4 items-center'>
                                        <p className='font-bold text-lg md:text-xl'>Rs {price * quantity + variationsTotalPrice * quantity}</p>
                                        <div className='flex items-center gap-x-1 xs:gap-x-2'>
                                            <button type="button" className='outline-none' onClick={() => setQuantity(quantity <= 1 ? quantity : quantity - 1)}>
                                                <CiCircleMinus size={20} className='size-4.5 sm:size-5 md:size-6 lg:size-8 cursor-pointer active:scale-95' />
                                            </button>
                                            <p className='font-semibold select-none' unselectable="on" >
                                                {quantity}
                                            </p>
                                            <button type="button" className='outline-none' onClick={() => setQuantity(quantity + 1)} >
                                                <CiCirclePlus size={20} className='size-4.5 sm:size-5 md:size-6 lg:size-8 cursor-pointer active:scale-95' />
                                            </button>
                                        </div>

                                        <AddToCart
                                            id={id}
                                            name={name}
                                            itemPrice={price}
                                            categories_id={categories_id}
                                            iscart={iscart}
                                            image={images[0].image}
                                            variationsTotalPrice={variationsTotalPrice}
                                            // @ts-ignore
                                            variations={[...checkedVariations, baseVariation]}
                                            // @ts-ignore
                                            variationIds={[...checkedVariationIds, baseVariation?.id]}
                                            quantity={quantity}
                                            setQuantity={setQuantity}
                                            closeModal={closeModal}
                                        />
                                    </div>
                                ) : (
                                    <div className='flex justify-between items-center mx-auto'>
                                        <div className='flex items-center gap-x-1 xs:gap-x-2'>
                                            <button type="button" className='outline-none' onClick={() => decreaseQuantity(productIndexInCart!)}>
                                                <CiCircleMinus size={20} className='size-4.5 sm:size-5 md:size-6 lg:size-8 cursor-pointer active:scale-95' />
                                            </button>
                                            <p className='font-semibold select-none' unselectable="on" >
                                                {productQuantityInCart}
                                            </p>
                                            <button type="button" className='outline-none' onClick={() => increaseQuantity(productIndexInCart!)} >
                                                <CiCirclePlus size={20} className='size-4.5 sm:size-5 md:size-6 lg:size-8 cursor-pointer active:scale-95' />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal>
            )}

        </div>
    )
}

export default PlusButton
